function AutoResponseAnswer({
  lang,
  answer,
  assignList,
  info,
  setInfo,
  autoResponseAnswer,
  setAutoResponseAnswer,
  status,
}) {
  let actionList = {
    default: {
      action: "default",
      text: "__greeting_add_to_team",
    },
    assign: {
      action: "assign",
      text: "__greeting_add_to_user",
    },
    chatbot: {
      action: "chatbot",
      text: "__greeting_add_to_chatbot",
    },
  };
  let answerTypeList = {
    text: {
      value: "text",
      text: "__greeting_type_text",
    },
    tel: {
      value: "tel",
      text: "__greeting_type_tel",
    },
    url: {
      value: "url",
      text: "__greeting_type_url",
    },
  };

  let selectedAnswerType = answerTypeList[answer.type].text;
  let selectedAction = actionList[answer.action].text;

  const updateAnswer = (answer) => {
    let allAnswer = _.cloneDeep(autoResponseAnswer);
    let newInfo = _.cloneDeep(info);
    allAnswer[answer.order - 1] = answer;
    setAutoResponseAnswer(allAnswer);
    if (status === "business_hours")
      newInfo.settingInfo.greeting_message.question.answer = allAnswer;
    else
      newInfo.settingInfo.awayBusinessMessage.greeting_message.question.answer =
        allAnswer;
    setInfo(newInfo);
  };

  const selectTeam = (team_id) => {
    answer.assignData = assignList.teams[team_id];
    updateAnswer(answer);
  };

  const selectAdmin = (nexus_user_id) => {
    answer.assignData = assignList.agents[nexus_user_id];
    updateAnswer(answer);
  };

  const selectAction = (action) => {
    selectedAction = actionList[action].text;
    answer.action = action;
    if (action == "assign") {
      answer.assignData = assignList.agents[_.keys(assignList.agents)[0]];
    } else {
      answer.assignData = assignList.teams["reception"];
    }
    updateAnswer(answer);
  };

  const toggleTransfer = (action) => {
    answer.transferActive = action;
    updateAnswer(answer);
  };

  const toggleChatbot = (action) => {
    answer.chatbotActive = action;
    updateAnswer(answer);
  };

  const validateValue = () => {
    if (answer.text) {
      if (answer.type === "tel") {
        var telRegex = /^[\d\+]+$/gi;
        answer.invalid = !telRegex.test(answer.text_value);
      } else if (answer.type === "url") {
        var urlRegex = /(https?:\/\/)/gi;
        answer.invalid = !urlRegex.test(answer.text_value);
      } else {
        answer.invalid = false;
      }
    } else {
      answer.invalid = false;
    }
  };

  const removeAnswer = (answer) => {
    answer.deleted = true;
    updateAnswer(answer);
  };

  const selectAnswerType = (type) => {
    selectedAnswerType = answerTypeList[type].text;
    answer.type = type;
    validateValue();
    updateAnswer(answer);
  };

  const handleChange = (event, status) => {
    const newText = event.target.value;
    answer[status] = newText;
    validateValue();
    updateAnswer(answer);
  };

  return (
    <div>
      {!answer.deleted && (
        <div class="greeting-item">
          <div class="sp-row" style={{ justifyContent: "flex-end" }}>
            <i
              class="icon-cancel sp-text-help"
              style={{ cursor: "pointer" }}
              onClick={() => removeAnswer(answer)}
            ></i>
          </div>
          <div class="sp-col right">
            <div class="sp-row">
              <div class="sp-col -w35 answer-label">
                <span class="sp-text">
                  {lang.__greeting_answer_text}
                  {answer.order}
                </span>
              </div>
              <div class="sp-col">
                <input
                  className={`sp-input sp-col ${
                    !answer.text && answer.lastItem ? "has-error" : ""
                  }`}
                  value={answer.text}
                  onChange={(e) => handleChange(e, "text")}
                />
              </div>
            </div>
            <div class="sp-row">
              <div class="sp-col -w35 answer-label">
                <span class="sp-text">{lang.__greeting_answer_type}</span>
              </div>
              <div class="sp-col">
                <div class="sp-col dropdown sp-dropdown">
                  <div
                    class="dropdown-button dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    <div class="text">{lang[selectedAnswerType]}</div>
                    <i class="icon-sort-down"></i>
                  </div>
                  <div class="dropdown-menu sp-list">
                    {Object.entries(answerTypeList).map((value, index) => {
                      return (
                        <div
                          class="item -clickable sp-row -gap"
                          onClick={() => selectAnswerType(value[1].value)}
                        >
                          <div class="sp-col">
                            <p class="sp-text">{lang[value[1].text]}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div class="sp-row">
              {answer.type == "text" ? (
                <div class="sp-col -w35 answer-label" style={{ display: "flex", flexDirection: "row" }}>
                  <span class="sp-text">
                    {lang.__greeting_answer_value_reply}
                  </span>
                  <ReactTooltip text={lang.__greeting_answer_value_description}>
                    <i
                    class="icon-help-circled"
                    uib-tooltip="{{'__greeting_answer_value_description' | translate}}"
                    tooltip-placement="right"
                    tooltip-append-to-body="true"
                  ></i>
                  </ReactTooltip>
                </div>
              ) : (
                <div class="sp-col -w35 answer-label">
                  <span class="sp-text">{lang.__greeting_answer_value}</span>
                </div>
              )}
              <div class="sp-col">
                <input
                  className={`sp-input sp-col ${
                    answer.invalid ? "has-error" : ""
                  }`}
                  value={answer.text_value}
                  onChange={(e) => handleChange(e, "text_value")}
                />
              </div>
            </div>
            {answer.type === "text" && (
              <div class="sp-row">
                <div class="sp-col -w35 answer-label">
                  <span class="sp-text">
                    {lang.__greeting_answer_response_text}
                  </span>
                </div>
                <div class="sp-col">
                  <textarea
                    class="sp-input"
                    value={answer.response_text}
                    onChange={(e) => handleChange(e, "response_text")}
                  ></textarea>
                </div>
              </div>
            )}
            {answer.type === "text" && (
              <Greeting_message_assign_action
                lang={lang}
                actionList={actionList}
                selectTeam={selectTeam}
                selectAdmin={selectAdmin}
                answer={answer}
                selectedAction={selectedAction}
                assignList={assignList}
                selectAction={selectAction}
                toggleTransfer={toggleTransfer}
                toggleChatbot={toggleChatbot}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
